import axios from "axios";
import { authURL } from "../../pages/auth/googleApi";
import { apiUrl } from "../baseUrl";
let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
};
export const instance = axios.create({
    baseURL: apiUrl,
    headers: headers,
});

export async function postRequest(requestUrl: string, data: any) {
    try {
        const token = localStorage.getItem("token");
        const response = await instance.post(requestUrl, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;

    } catch (error: any) {
        if (error?.response?.data?.message === "Invalid or expired token" || error?.response?.data?.message === "Session not found. Please log in again." || error?.status === 401) {
            const keyToKeep = "googleToken";
            const valueToKeep = localStorage.getItem(keyToKeep);
            localStorage.clear();
            if (valueToKeep) {
                localStorage.setItem(keyToKeep, valueToKeep);
            }
            window.location.reload();
        }
        return error;
    }
}
export async function postRequestwithoutData(requestUrl: string, data: any) {
    try {
        const token = localStorage.getItem("token");
        const response = await instance.post(requestUrl, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;

    } catch (error: any) {
        if (error?.response?.data?.message === "Invalid or expired token" || error?.response?.data?.message === "Session not found. Please log in again." || error?.status === 401) {
            const keyToKeep = "googleToken";
            const valueToKeep = localStorage.getItem(keyToKeep);
            localStorage.clear();
            if (valueToKeep) {
                localStorage.setItem(keyToKeep, valueToKeep);
            }
            window.location.reload();
        }
        return error;
    }
}


export async function getRequest(requestUrl: string) {
    try {
        const token = localStorage.getItem("token");
        const response = await instance.get(requestUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        if (error?.response?.data?.message === "Invalid or expired token" || error?.response?.data?.message === "Session not found. Please log in again." || error?.status === 401) {
            const keyToKeep = "googleToken";
            const valueToKeep = localStorage.getItem(keyToKeep);
            localStorage.clear();
            if (valueToKeep) {
                localStorage.setItem(keyToKeep, valueToKeep);
            }
            window.location.reload();
        }
        return error;
    }
}
