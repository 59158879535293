
import { FC } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { authURL } from "./auth/googleApi";
import Slider from "react-slick";

const ChatModeSelection: FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <section className="chat-mode">
                <Container>
                    <Row className="justify-content-center align-items-center g-xl-5 g-lg-4 g-3">
                        <Col xxl={"auto"} xl={5} lg={6} md={7}>
                            <div className="mode-box">
                                <Image src={process.env.PUBLIC_URL + '/assets/img/class.png'} />
                                <h2>
                                    <span>Supervised</span> Simulations
                                </h2>
                                <p>
                                    Practice patient interactions in a classroom-based setting
                                </p>
                                <Button variant="" onClick={() => {
                                    localStorage.setItem('chat-mode', 'paid')
                                    navigate("/persona-selection")
                                }
                                }>Enter Clinic</Button>
                            </div>
                        </Col>
                        <Col xxl={"auto"} xl={5} lg={6} md={7}>
                            <div className="mode-box">
                                <Image src={process.env.PUBLIC_URL + '/assets/img/ind.png'} />
                                <h2>
                                    <span>Independent</span> Simulations
                                </h2>
                                <p>
                                    Refine your skills through self-paced practice, anytime, anywhere
                                </p>
                                <Button variant="" onClick={() => {
                                    navigate("/persona-selection");
                                    localStorage.setItem('chat-mode', 'free')
                                }
                                }>Enter Clinic</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}
export default ChatModeSelection;