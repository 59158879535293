import { FC, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row, Stack } from "react-bootstrap";
import Logo from "../../components/Logo";
import { useNavigate } from "react-router-dom";
import { eyeOff, eyeOn, submitIcon } from "../../utils/Icons";
import CustomError from "../../utils/CustomError";
import { loginDataUpdate, verifyPassword } from "../../api/request";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { jwtDecode } from 'jwt-decode';
import { authURL, clientID } from "./googleApi";


const Login: FC = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState("");
    const [userData, setData] = useState<any>("");
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            setError("");
        }, 3000);
        return () => {
            clearTimeout(timer);
        };
    });

    useEffect(() => {
        const hash = window.location.hash;
        const urlParams = new URLSearchParams(hash.replace('#', '?'));
        const accessToken = urlParams.get('access_token');
        if (accessToken) {
            localStorage.setItem('googleToken', accessToken);
        }
    }, []);

    useEffect(() => {
        const googleToken = localStorage.getItem('googleToken');
        if (!googleToken) {
            window.location.href = authURL;
        } else {
            fetchUserData();
        }
        const loginCheck = localStorage.getItem("normalLogin");
        if (loginCheck) {
            navigate("/chat-mode");
        }
    }, []);


    const fetchUserData = () => {
        const accessToken = localStorage.getItem("googleToken");
        fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setData(data);
                console.log(data, "data");
                localStorage.setItem("picture", data?.picture)
                if (data?.error === "invalid_request") {
                    window.location.href = authURL;
                }
            })
            .catch((error) => console.error('Error fetching user data:', error));
    };

    useEffect(() => {
        loginDataHandle();
    }, [userData]);

    const loginDataHandle = async () => {
        let payload = {
            user_id: userData?.email,
            name: userData?.name
        }
        const response = await loginDataUpdate(payload);
        if (response.flag) {
            localStorage.setItem("token", response?.result.token);
        }

    }

    const handleLogin = async () => {
        let payload = {
            code: password
        }
        const response = await verifyPassword(payload);
        if (response.flag) {
            localStorage.setItem("normalLogin", response?.flag);
            navigate("./chat-mode");
        }
        else {
            setError("show-error");
        }
    }

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    return (
        <>
            <section className="login-page h-100">
                <Container className="h-100">
                    <Row className="h-100 align-items-center justify-content-center">
                        <Col xxl={4} lg={5} md={8} className="text-center">
                            <Logo />
                            <Stack className="mt-5">
                                <InputGroup className="mb-3">
                                    <input
                                        className="form-control"
                                        autoComplete="new-password"
                                        type={showPassword !== true ? "password" : "text"}
                                        onChange={(e: any) =>
                                            setPassword(e.target.value)
                                        }
                                        onKeyDown={(key: any) => {
                                            if (key.key === "Enter") {
                                                handleLogin()
                                            }
                                        }}
                                        ref={inputRef}
                                    />
                                    <Button
                                        onClick={() => setShowPassword(prevState => !prevState)}
                                        type="button"
                                        variant="eye-icon"
                                    >
                                        {showPassword ? eyeOn() : eyeOff()}
                                    </Button>
                                    <Button
                                        type="button"
                                        variant=""
                                        className="submit-btn"
                                        onClick={() => handleLogin()}
                                    >
                                        {submitIcon()}
                                    </Button>
                                </InputGroup>
                            </Stack>
                            <CustomError class={`mt-3 ${error}`} message="The Password you entered is incorrect." />
                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    )
}
export default Login;