import { FC, useEffect, useState } from "react";
import { Col, Container, FormLabel, InputGroup, Row, Stack } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { eyeOff, eyeOn, submitIcon } from "../../utils/Icons";
import CustomError from "../../utils/CustomError";
import { adminVerify, getPersonas, personaUpdate, getDefaultPersonas } from "../../api/request";

interface adminModal {
    show: boolean;
    onClose: () => void;
}

const AdminModal: FC<adminModal> = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const [login, setLogin] = useState<any>(false);
    const [error, setError] = useState("");
    const [errorAdmin, setErrorAdmin] = useState(false);
    const [password, setPassword] = useState<string>();
    const [personaList, setPersonaList] = useState<any>([]);
    const [selectedPersona, setPersona] = useState(personaList && personaList[0]?.name);
    const [listKey, setListKey] = useState<any>({
        provide: "",
        follow: "",
        reaction: "",
    });

    useEffect(() => {
        const freeChat = localStorage.getItem("chat-mode");
        const loginType = freeChat === "free" ? true : false;
        setLogin(loginType);
        fetchPersonas();
    }, [props.show]);



    useEffect(() => {
        console.log(login, "login");
        if (login) {
            setPersona(personaList && personaList[0]?.name);
            console.log(selectedPersona, "selectedPersona");
        }
    }, [login]);


    useEffect(() => {
        const singleData = personaList?.filter((item: any) => item?.name === selectedPersona);
        setListKey(() => (
            {
                provide: singleData[0]?.willingness_to_provide_information || "",
                follow: singleData[0]?.willingness_to_follow_recommendations || "",
                reaction: singleData[0]?.emotional_reaction_to_recommendations || "",
            }
        ));
    }, [selectedPersona, personaList]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setError("");
            setErrorAdmin(false);
        }, 3000);
        return () => {
            clearTimeout(timer);
        };
    }, [errorAdmin, error]);

    const fetchPersonas = async () => {
        const chatMode = localStorage.getItem('chat-mode');
        let param = `?type=${chatMode}`;
        const response = await getPersonas(param);
        if (response?.flag) {
            setPersonaList(response?.result?.data);
        }
    }
    const handleLogin = async () => {
        let payload = {
            admin_code: password
        }
        const response = await adminVerify(payload);
        if (response.flag) {
            setLogin(true);
        }
        else {
            setError("show-error");
        }
    }

    const handleUpdate = async () => {
        let payload = {
            willingness_to_provide_information: listKey?.provide,
            willingness_to_follow_recommendations: listKey?.follow,
            emotional_reaction_to_recommendations: listKey?.reaction
        }
        const response = await personaUpdate(selectedPersona, payload);
        if (response?.flag) {
            setErrorAdmin(true);
            fetchPersonas();
            props.onClose();
        }
    }

    useEffect(() => {
    }, [listKey]);
    return (
        <>
            {errorAdmin && (
                <CustomError class={`mt-3 show-error right-side`} message="Persona details updated successfully." />
            )}
            <Modal className="admin-modal" size="lg" show={props.show} onHide={props.onClose} keyboard={false} backdrop={!login ? true : "static"} centered>
                {!login ? <>
                    <Modal.Header className="justify-content-center pt-lg-5 pt-2">
                        <Modal.Title>
                            Admin Verification
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-lg-5 pb-2">
                        <section className="login-page p-0">
                            <Container className="">
                                <Row className=" align-items-center justify-content-center">
                                    <Col xxl={7} lg={9} md={12} className="text-center">
                                        <Stack className="mt-4">
                                            <InputGroup >
                                                <input
                                                    className="form-control"
                                                    autoComplete="new-password"
                                                    type={showPassword !== true ? "password" : "text"}
                                                    onChange={(e: any) =>
                                                        setPassword(e.target.value)
                                                    }
                                                    onKeyDown={(key: any) => {
                                                        if (key.key === "Enter") {
                                                            handleLogin()
                                                        }
                                                    }}
                                                />
                                                <Button
                                                    onClick={() => setShowPassword(prevState => !prevState)}
                                                    type="button"
                                                    variant="eye-icon"
                                                >
                                                    {showPassword ? eyeOn() : eyeOff()}
                                                </Button>
                                                <Button
                                                    type="button"
                                                    variant=""
                                                    className="submit-btn"
                                                    onClick={() =>
                                                        handleLogin()
                                                    }
                                                >
                                                    {submitIcon()}
                                                </Button>
                                            </InputGroup>
                                        </Stack>
                                        <CustomError class={`mt-3 ${error}`} message="The Password you entered is incorrect." />
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </Modal.Body>
                </> :
                    <>
                        <Modal.Header className="justify-content-between row  g-0 text-center p-lg-4 p-2 border-bottom">
                            <Modal.Title className="col text-start">
                                Update Persona
                            </Modal.Title>
                            <Col xs="auto">
                                <InputGroup className="select-control">
                                    <select
                                        className="form-control text-start"
                                        onChange={(e: any) =>
                                            setPersona(e.target.value)
                                        }
                                    >
                                        {personaList?.map((item: any, index: any) => (
                                            <option key={index} value={item?.name}>{item?.baseline?.profile?.name}</option>
                                        ))}
                                    </select>
                                </InputGroup>
                            </Col>
                        </Modal.Header>
                        <Modal.Body className="update-persona p-lg-4 p-3 border-bottom">
                            <Row className="g-2">
                                <Col lg={6} className="">
                                    <div className="border-end me-lg-3 pe-lg-2 h-100 mb-lg-0 mb-3">
                                        <ul className="ps-2 update-persona-text">
                                            <li><strong>Step 1</strong>-Use the dropdown at the top to choose a persona (e.g., "John").</li>
                                            <li><strong>Step 2</strong>-Set attributes:
                                                <ul>
                                                    <li>Information: Open/Guarded</li>
                                                    <li>Recommendations: Cooperative/Resistant</li>
                                                    <li>Reaction: Calm/Anxious</li>
                                                </ul>
                                            </li>
                                            <li><strong>Step 3</strong>-Click Update or Cancel.</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <InputGroup className="d-block mt-lg-0 mt-3">
                                        <FormLabel >Willingness to provide information</FormLabel>
                                        <div key={`inline-radio`} className="d-block">
                                            <Form.Check
                                                label="Open"
                                                name="provide"
                                                type="radio"
                                                value="Open"
                                                id={`inline-radio-1`}
                                                checked={listKey.provide === "Open"}
                                                onChange={(e: any) =>
                                                    setListKey((prevState: any) => (
                                                        {
                                                            ...prevState,
                                                            provide: e?.target?.value,
                                                        }))
                                                }
                                            />
                                            <Form.Check
                                                value="Guarded"
                                                label="Guarded"
                                                name="provide"
                                                type="radio"
                                                checked={listKey.provide === "Guarded"}
                                                id={`inline-radio-2`}
                                                onChange={(e: any) =>
                                                    setListKey((prevState: any) => (
                                                        {
                                                            ...prevState,
                                                            provide: e?.target?.value,
                                                        }))
                                                }
                                            />
                                        </div>
                                    </InputGroup>

                                    <InputGroup className="my-4 d-block">
                                        <FormLabel>Willingness to follow recommendations</FormLabel>
                                        <div key={`inline-radio`} className="d-block">
                                            <Form.Check
                                                label="Cooperative"
                                                name="follow"
                                                type="radio"
                                                value="Cooperative"
                                                id={`inline-radio-3`}
                                                checked={listKey.follow === "Cooperative"}
                                                onChange={(e: any) =>
                                                    setListKey((prevState: any) => (
                                                        {
                                                            ...prevState,
                                                            follow: e?.target?.value,
                                                        }))
                                                }
                                            />
                                            <Form.Check
                                                value="Resistant"
                                                label="Resistant"
                                                name="follow"
                                                type="radio"
                                                id={`inline-radio-4`}
                                                checked={listKey.follow === "Resistant"}

                                                onChange={(e: any) =>
                                                    setListKey((prevState: any) => (
                                                        {
                                                            ...prevState,
                                                            follow: e?.target?.value,
                                                        }))
                                                }
                                            />
                                        </div>
                                    </InputGroup>

                                    <InputGroup className="d-block">
                                        <FormLabel>Emotional reaction to recommendations</FormLabel>
                                        <div key={`inline-radio`} className="d-block">
                                            <Form.Check
                                                label="Calm"
                                                name="reaction"
                                                type="radio"
                                                value="Calm"
                                                id={`inline-radio-5`}
                                                checked={listKey.reaction === "Calm"}
                                                onChange={(e: any) =>
                                                    setListKey((prevState: any) => (
                                                        {
                                                            ...prevState,
                                                            reaction: e?.target?.value,
                                                        }))
                                                }
                                            />
                                            <Form.Check
                                                value="Anxious"
                                                label="Anxious"
                                                name="reaction"
                                                type="radio"
                                                id={`inline-radio-6`}
                                                checked={listKey.reaction === "Anxious"}
                                                onChange={(e: any) =>
                                                    setListKey((prevState: any) => (
                                                        {
                                                            ...prevState,
                                                            reaction: e?.target?.value,
                                                        }))
                                                }
                                            />
                                        </div>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-end mb-0 g-1 text-center px-lg-4 px-2 py-lg-2 py-1">
                            <Col md="auto" xs="auto">
                                <Button
                                    variant="secondary"
                                    onClick={() => handleUpdate()}
                                >
                                    Update
                                </Button>
                                <Button
                                    className="ms-2"
                                    variant="primary"
                                    onClick={() => props.onClose()}
                                >
                                    Cancel
                                </Button>

                            </Col>
                        </Modal.Footer>
                    </>}
            </Modal >
        </>
    )
}
export default AdminModal;