import { FC, useEffect, useState } from "react";
import Logo from "./Logo";
import Dropdown from "react-bootstrap/Dropdown";
import { Col, Container, Image, Row, Stack } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import HowToUseModal from "./modals/HowToUseModal";
import Heading from "./Heading";
import AdminModal from "./admin/AdminModal";
import { googleLogout } from '@react-oauth/google';
import { cleanSessionHistory, logOut } from "../api/request";
import { authURL, clientID } from "../pages/auth/googleApi";
import FreeModal from "./admin/FreeModal";

declare global {
    interface Window {
        gapi: any;
    }
}

const Header: FC = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [chatMode, setChatMode] = useState<string | null>(null);
    const location = useLocation();

    const profilePic: any = localStorage.getItem("picture");

    useEffect(() => {
        if (location.pathname !== '/chat-screen') {
            document.body?.classList.remove('hide-title');
        }
        else {
            document.body?.classList.add('hide-title');
        }
    }, [location.pathname]);

    useEffect(() => {
        const chatModeType = localStorage.getItem('chat-mode');
        setChatMode(chatModeType);
    }, [location])

    const handleClick = async () => {
        if (location.pathname === '/chat-screen') {
            const isConfirmed = window.confirm('The chat history will be lost. Are you sure you want to continue?');
            if (isConfirmed) {
                const response = await cleanSessionHistory();
                if (response?.flag) {
                    navigate('/chat-mode');
                }
            } else {
                // console.log('User canceled the action');
            }
        }
        else {
            navigate('/chat-mode');
        }
    };


    const googleLogOutFn = async () => {
        try {
            const result = await logOut();
            if (result?.flag) {
                googleLogout();
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = authURL;
            }
        } catch (error) {
            console.error("Error during Google logout:", error);
        }
    }

    return (
        <>
            <HowToUseModal setShow={show} onClose={() => setShow(false)} />
            <AdminModal show={admin} onClose={() => setAdmin(false)} />
            <header>
                <Container>
                    <Row className="justify-content-between">
                        <Col id="no-print" md={{ order: 1, span: 2 }} xs={{ order: 1, span: 6 }}>
                            <div onClick={handleClick}>
                                <Logo />
                            </div>
                        </Col>
                        {location.pathname !== "/chat-screen" ?
                            ""
                            :
                            <Col md={{ order: 2, span: 8 }} xs={{ order: 3, span: 12 }}>
                                <Heading />
                            </Col>
                        }
                        <Col id="no-print" md={{ order: 3, span: 2 }} xs={{ order: 2, span: 6 }} className="text-end">
                            <Stack direction="horizontal" gap={3} className="justify-content-end">
                                <Dropdown align="end">
                                    <Dropdown.Toggle variant="" className="p-0" id="dropdown-basic">
                                        <div className="hamburger" id="hamburger-1">
                                            <span className="line"></span>
                                            <span className="line"></span>
                                            <span className="line"></span>
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#" onClick={handleClick}>Home</Dropdown.Item>
                                        {chatMode === "paid" && location.pathname === "/persona-selection" && (
                                            <Dropdown.Item href="#"
                                                onClick={() => {
                                                    setAdmin(true);
                                                }}
                                            >
                                                Admin
                                            </Dropdown.Item>
                                        )}

                                        <Dropdown.Item href="#"
                                            onClick={() => {
                                                setShow(true);
                                            }}
                                        >Instructions</Dropdown.Item>
                                        <Dropdown.Item href="/feedback" target="_blank">Feedback</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown align="end">
                                    <Dropdown.Toggle variant="" className="p-0" id="dropdown-basic">
                                        <div className="profile-icon">
                                            <Image src={profilePic && profilePic} />
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            href="#" onClick={() => {
                                                googleLogOutFn();
                                                // googleLogout();
                                            }}
                                        >Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Stack>
                        </Col>
                        {location.pathname === "/chat-screen" ?
                            ""
                            : <Col className="mt-4 mb-3" xs={{ order: 3, span: 12 }}>
                                <Heading />
                            </Col>}
                    </Row>
                </Container>
            </header >
        </>
    )
}
export default Header;