import { getRequest, postRequest, postRequestwithoutData } from "./components/api";
import * as endPoints from "./endPoints"

export const loginDataUpdate = async (data: any) => {
    try {
        let result = await postRequest(`${endPoints.loginData}`, data);
        return result;
    } catch (error) {
        console.log(error);
    }
};
export const verifyPassword = async (data: any) => {
    try {
        let result = await postRequest(`${endPoints.getVerifyPassword}`, data);
        return result;
    } catch (error) {
        console.log(error);
    }
};

export const getPersonas = async (param: any) => {
    try {
        let result = await getRequest(`${endPoints.getPersonas}${param}`);
        return result;
    } catch (error) {
        console.log(error);
    }
};
export const getMessage = async (data: any) => {
    try {
        let result = await postRequestwithoutData(`${endPoints.askMessage}`, data);
        return result;
    } catch (error) {
        console.log(error);
    }
};

export const generateSummary = async (name: any) => {
    try {
        let result = await getRequest(`${endPoints.generateSummary}?name=${name}`);
        return result;
    } catch (error) {
        console.log(error);
    }
};
export const logOut = async () => {
    try {
        let result = await getRequest(`${endPoints.logOut}`);
        return result;
    } catch (error) {
        console.log(error);
    }
};
export const adminVerify = async (data: any) => {
    try {
        let result = await postRequest(`${endPoints.adminLogin}`, data);
        return result;
    } catch (error) {
        console.log(error);
    }
};
export const adminHearingVerify = async (data: any) => {
    try {
        let result = await postRequest(`${endPoints.adminHearingLogin}`, data);
        return result;
    } catch (error) {
        console.log(error);
    }
};
export const personaUpdate = async (name: any, data: any) => {
    try {
        let result = await postRequest(`${endPoints.personaUpdate}?name=${name}`, data);
        return result;
    } catch (error) {
        console.log(error);
    }
};
export const getDefaultPersonas = async () => {
    try {
        let result = await getRequest(`${endPoints.getDefaultPersona}`);
        return result;
    } catch (error) {
        console.log(error);
    }
};
export const cleanSessionHistory = async () => {
    try {
        let result = await getRequest(`${endPoints.cleanSession}`);
        return result;
    } catch (error) {
        console.log(error);
    }
};